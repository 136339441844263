<template>
  <div class="content">
    <div class="screenView">
      <el-form :model="form" class="form-inline" inline>
        <el-form-item>
          <el-select
            v-model="form.category"
            placeholder="新聞類別"
            @change="select_change"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) of categoryList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.year"
            placeholder="新聞年份"
            @change="select_change"
            clearable
          >
            <el-option
              :label="item.years"
              :value="item.years"
              v-for="(item, index) of yearList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-row>
        <template v-if="list.length != 0">
          <div
            v-for="(item, index) of list"
            :key="index"
            :class="index > 2 ? 'min_col' : ''"
          >
            <el-col :span="24" v-if="index == 0">
              <router-link
                :to="`/news_details?id=${item.id}}`"
                class="item item_one"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time class="NotoSansTCLight">{{ item.years }}</time>
                    <span>{{ item.keywords }}</span>
                  </div>
                  <h3 class="NotoSansTCBold">{{ item.title }}</h3>
                  <div class="txtCon">{{ item.description }}</div>
                </div>
              </router-link>
            </el-col>
            <el-col :span="8" v-else-if="index == 1">
              <router-link
                :to="`/news_details?id=${item.id}}`"
                class="item item_two"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.keywords }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                </div>
              </router-link>
            </el-col>
            <el-col :span="16" v-else-if="index == 2" class="dr">
              <router-link
                :to="`/news_details?id=${item.id}}`"
                class="item item_three"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.keywords }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                </div>
              </router-link>
            </el-col>
            <el-col :span="24" v-else>
              <router-link
                :to="`/news_details?id=${item.id}}`"
                class="item item_four"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.keywords }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                </div>
              </router-link>
            </el-col>
          </div>
        </template>
        <empty-data icon-size="60px" font-size="16px" v-else />
      </el-row>
      <el-button
        class="see_more"
        @click="getData"
        v-if="list.length < total"
        round
        >查看更多</el-button
      >
      <el-button class="see_more" @click="getData" v-else disabled round
        >没有更多了</el-button
      >
    </div>
    <div class="subscribeNewsView">
      <subscribe-news />
    </div>
  </div>
</template>

<script>
import subscribeNews from "@/components/subscribeNews";
export default {
  name: "News",
  components: {
    subscribeNews,
  },
  data() {
    return {
      categoryList: [],
      yearList: [],
      form: {
        category: "",
        year: "",
      },
      list: [],

      pageIndex: 1,
      pageSize: 6,
      total: 0,
    };
  },
  mounted() {
    this.getScreen();
    this.getData();
  },
  methods: {
    /**
     * 获取筛选
     */
    getScreen() {
      this.$http.news_screen().then((res) => {
        if (res.code == 200) {
          this.categoryList = res.data.cate;
          this.yearList = res.data.years;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**触发筛选 */
    select_change() {
      this.pageIndex = 1;
      this.list = [];
      this.getData();
    },
    getData() {
      let page = {
        page: this.pageIndex,
        limit: this.pageSize,
      };
      let screen = {
        cate_id: this.form.category,
        years: this.form.year,
      };
      this.$http.news({ ...page, ...screen }).then((res) => {
        if (res.code == 200) {
          this.list = this.list.concat(res.data.data);
          this.total = res.data.total;
          this.pageIndex++;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/news.scss";
.main {
  .item {
    &:hover {
      .txtView {
        h3 {
          color: #0075c1;
        }
      }
    }
  }
}
</style>